import React, { useEffect, useState } from "react"
import { Button, Offcanvas, Form, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import InfoScrollbar from "../UserInfoScroll"
import { createStaffToPay, getWindowsLocation,getDeviceCode } from "../../api/app.ts"

const TryFree = props => {
  const [show, setShow] = useState(false)

  const { setShowScroll, priceIds, cbUrl } = props

  const handleClose = () => {
    setShowScroll(true)
    setShow(false)
  }
  const handleShow = () => {
    setShowScroll(false)
    setShow(true)
  }

  const [validated, setValidated] = useState(false)

  const [emailSub, setEmailSub] = useState(true)

  const [email, setEmail] = useState("")

  const [priceId, setPriceId] = useState(priceIds.annually)

  const [showPop, setShowPop] = useState(false)
  const handlePopClose = () => setShowPop(false)

  const [showPlan, setShowPlan] = useState(false)
  const handlePopPlanClose = () => setShowPlan(false)

  const [popText, setPopText] = useState("")
  const [errPop, setErrPop] = useState(false)

  const handleSubmit = async event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()
    if (!form.checkValidity()) {
      setValidated(true)
      return
    }
    const params = {
      path: getWindowsLocation(),
      emailSub,
      email,
      priceId,
      origin: getDeviceCode(),
      autoEmail:true,
      ...cbUrl,
    }
    const { data, success } = await createStaffToPay(params)
    if (success) {
      const { popup, url } = data
      url && window.open(data.url, "_self")
      if (popup) {
        setPopText(popup)
        setShowPop(true)
      }
    } else {
      setErrPop(true)
    }
  }

  const toCubo = () => {
    window.open("https://cubo.to", "_self")
  }

  const tabs = [
    { name: "Monthly", priceId: priceIds.monthly, price: 15 },
    { name: "Annually🔥", priceId: priceIds.annually, price: 12 },
  ]
  const handleShowAndSetModalPosition = () => {
    setShowPlan(true)

    const offsetY = getElementPagePosition(
      document.getElementById("planModalButton")
    )
    setTimeout(() => {
      const target = document.getElementById("planModalDialog")
      if (target) {
        target.style.top = `${offsetY}px`
      }
    })
  }

  function getElementPagePosition(element) {
    //计算y坐标
    let actualTop = element.offsetTop
    let current = element.offsetParent
    while (current !== null) {
      actualTop += current.offsetTop + current.clientTop
      current = current.offsetParent
    }
    //返回结果
    return actualTop
  }

  return (
    <>
      <section className="tryFree" id="tryFree">
        <Button
          type="button"
          variant="primary"
          onClick={handleShow}
          className="btn"
          id="threeTryOuterBtn"
        >
          Try Now
          <img
            className="arrow"
            src="/assets/images/arrow.png"
            alt="try free"
          />
        </Button>
      </section>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="tryFree-offcanvas  tryFree-offcanvas3"
      >
        <InfoScrollbar extraClass={"tryFree"} />

        <div
          onClick={handleShowAndSetModalPosition}
          className="plan-modal"
          id="planModalButton"
        >
          <div className="left">
            {priceId === priceIds.monthly ? tabs[0].name : tabs[1].name}
          </div>
          <div className="right">
            <span className="top">
              $
              <span className="text">
                {priceId === priceIds.monthly ? 7 : 29.9}
              </span>
            </span>
            <span className="button">
              ${priceId === priceIds.monthly ? "15/month" : "144/year"}
            </span>
          </div>
          <img
            src="/assets/icons/select_down.png"
            width={16}
            height={16}
            className="icon"
          />
          <div className="saletag">Flash sale</div>
        </div>

        <Modal
          show={showPlan}
          onHide={handlePopPlanClose}
          contentClassName="plan-modal-content"
          backdropClassName="plan-modal-backdrop"
          dialogClassName="plan-modal-dialog"
          id="planModalDialog"
        >
          <Modal.Body>
            <div
              className="plan-detail"
              onClick={() => {
                setPriceId(priceIds.annually)
                handlePopPlanClose()
              }}
            >
              <div className="left">{tabs[1].name}</div>
              <div className="right">
                <span className="top">
                  $<span className="text"> 29.9</span>
                </span>
                <span className="button"> $144/year</span>
              </div>
            </div>
            <div className="border" />
            <div
              className="plan-detail"
              onClick={() => {
                setPriceId(priceIds.monthly)
                handlePopPlanClose()
              }}
            >
              <div className="left">{tabs[0].name}</div>
              <div className="right">
                <span className="top">
                  $<span className="text"> 7 </span>
                </span>
                <span className="button"> $ 15/month </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className="list">
          <div className="list_item">
            <img src="/assets/icons/icon1.png" />
            Instant Activation
          </div>
          <div className="list_item">
            <img src="/assets/icons/schedule_icon_v2.png" />
            14-day money back guarantee
          </div>
          <div className="list_item">
            <img src="/assets/icons/icon3.png" />
            Chat & Email Support
          </div>
        </div>

        <div className="tryFreeForm">
          <Form noValidate onSubmit={handleSubmit} validated={validated}>
            <Form.Group className="tryFreeForm-input3">
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                id="threeEmailInput"
              />
              <Form.Control.Feedback type="invalid">
                Enter a valid email address
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit" id="threeTrySubmit">
              Try Now
              <img
                className="arrow"
                src="/assets/images/arrow.png"
                alt="try free"
              />
            </Button>
          </Form>
        </div>

        <Form.Check
          className="tryFreeTips"
          type="checkbox"
          label="Get Daily Online Business Tips for Free."
          defaultChecked
          onChange={e => setEmailSub(e.target.checked)}
        />

        <div className="tryFreeFooter">
          By clicking Try Now, you agree to the
          <span>
            <Link to="/termsofservice">&nbsp;Terms of Service&nbsp;</Link>
          </span>
          and
          <span>
            <Link to="/privacypolicy">&nbsp;Privacy Policy&nbsp;</Link>
          </span>
        </div>
      </Offcanvas>

      <Modal
        show={showPop}
        onHide={handlePopClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <img src="/assets/icons/logo.png" className="poplogo" />
        </Modal.Header>
        <Modal.Body>{popText}</Modal.Body>
        <Modal.Footer className="popFooter">
          <Button variant="primary" className="popBtn" onClick={toCubo}>
            confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TryFree
